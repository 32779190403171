.background-image {
    height: 100vh; /* Full viewport height */
    background: url('background.png') no-repeat center center fixed;
    background-size: cover;
    margin: 0;
    padding: 0;
}

.main-header {
    position: absolute;
    right: 10%; /* Adjust as necessary */
    top: 40%;
    font: 19px sans-serif;
    text-align: right;
    color: black; /* Ensure text is readable against background */
}

.search-form {
    position: absolute;
    right: 10%; /* Adjust as necessary */
    top: 45%;
}

.search-bar{
    position: relative;
    width: 25em;
}